.image-gallery-image {
    max-height: 60vh; /* Reduced height */
    max-width: 80%; /* Limit width to avoid pushing the bounds of the modal */
    object-fit: contain; /* Ensure the image fits nicely within the constraints without stretching */
    margin: auto; /* Center the image horizontally */
  }
  
  .image-gallery-thumbnail {
    max-height: 50px;  /* Smaller thumbnails for navigation */
  }
  
  .image-gallery-slide img {
    object-fit: contain; /* Makes sure images are scaled correctly within the frame */
  }